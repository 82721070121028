<template>
  <div>
    <b-card-actions
      v-if="approvalFlow"
      :title="$t('ApprovalFlow.DetailsCardTitle', { id: approvalFlow.id })"
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <b-row>
        <b-col md="4">
          <FormulateInput
            id="approval-flow-name"
            v-model="approvalFlow.name"
            type="label"
            :label="$t('Fluxo')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            v-if="approvalObject"
            id="approval-flow-object"
            v-model="approvalObject"
            type="label"
            :label="$t('Objeto de aprovação')"
            :router-to="approvalObjectRoute"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="approval-flow-created-by"
            v-model="approvalFlow.createdBy"
            type="label"
            :label="$t('Solicitante')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            :value="approvalFlow.status"
            type="label"
            :label="$t('Status')"
            is-badge
            :options="approvalFlowStatusOptions"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            :value="approvalFlow.createdDate"
            type="label"
            :label="$t('Criado em')"
            filter="datetime"
          />
        </b-col>
      </b-row>
      <div class="row">
        <b-col md="12">
          <b-tabs fill>
            <b-tab :title="$t('Fluxo')">
              <div
                v-for="step in approvalFlow.steps"
                :key="step.id"
                class="col-md-12"
              >
                <div class="step">
                  <h3>
                    <approval-flow-status :status="step.status" />
                    {{ $t('ApprovalFlow.StepTitle', { sequence: step.sequence }) }} -
                    {{ step.name }}
                  </h3>
                  <b-row>
                    <b-col
                      v-for="approvalGroup in step.approvalGroups"
                      :key="approvalGroup.id"
                      md="4"
                    >
                      <div class="approval-group">
                        <h4>
                          <approval-flow-status :status="approvalGroup.status" />
                          {{ approvalGroup.approvalGroup.name }}
                        </h4>
                        <p
                          v-for="approval in approvalGroup.approvals"
                          :key="approval.id"
                        >
                          <approval-flow-status :status="approval.status" />
                          {{ approval.responsibleUserName }}
                          <em
                            v-if="approval.approvalDate"
                          >({{ approval.approvalDate | datetime }})</em>
                          <span
                            v-if="approval.observation"
                            class="ml-25"
                          >
                            {{ approval.observation }}
                          </span>
                        </p>
                      </div>
                    </b-col>
                    <b-col
                      v-if="step.approvalType == 'Owner'"
                      :key="step.id"
                      md="4"
                    >
                      <div class="approval-group">
                        <h4>
                          <approval-flow-status :status="step.status" />
                          Solicitante
                        </h4>
                        <p>
                          <approval-flow-status
                            :status="step.approvalFlowTransactionApproval.status"
                          />
                          {{ step.approvalFlowTransactionApproval.responsibleUserName }}
                          <em
                            v-if="step.approvalFlowTransactionApproval.approvalDate"
                          >({{
                            step.approvalFlowTransactionApproval.approvalDate | datetime
                          }})</em>
                          <span
                            v-if="step.approvalFlowTransactionApproval.observation"
                            class="ml-25"
                          >
                            {{ step.approvalFlowTransactionApproval.observation }}
                          </span>
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="step.documents">
                    <b-col md="12">
                      <p
                        v-for="document in step.documents"
                        :key="document.id"
                      >
                        <a
                          :href="document.url"
                          class="nav-link"
                          target="_blank"
                        >
                          <b-img
                            :src="getIconDocument(document.url)"
                            height="auto"
                            width="20"
                            class="mr-1"
                          />
                          <span>{{ document.name }}</span>
                        </a>
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-tab>

            <b-tab
              :title="$t('Histórico')"
              @click="loadEvents()"
            >
              <div class="step">
                <app-timeline>
                  <app-timeline-item
                    v-for="item in events"
                    :key="item.id"
                    :title="item.description"
                    :subtitle="item.createdBy"
                    :time="item.createdDate | datetime"
                    :variant="getStatusIcon(item.status)"
                  />
                </app-timeline>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </div>
    </b-card-actions>

    <e-modal-approval-flow-action
      ref="modalApproval"
      @after-confirm="getData()"
    />

    <fab
      v-if="$can('Update', 'ApprovalFlow')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      @approve="openActionModal('Approve')"
      @disapprove="openActionModal('Disapprove')"
      @ask-changes="openActionModal('AskChanges')"
      @cancel="cancel()"
    />
  </div>
</template>

<script>
import { BRow, BCol, BTabs, BTab, BImg } from 'bootstrap-vue'
import fab from '@/views/components/FAB.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { stringUtils, approvalFlowDomains, documentIcons } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
// import EStatusBadge from '@/views/components/EStatusBadge.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ApprovalFlowStatus from './components/ApprovalFlowStatus.vue'
import EModalApprovalFlowAction from './components/EModalApprovalFlowAction.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BImg,
    BCardActions,
    fab,
    ApprovalFlowStatus,
    EModalApprovalFlowAction,
    AppTimeline,
    AppTimelineItem,
    // EStatusBadge,
  },

  mixins: [stringUtils, approvalFlowDomains, documentIcons],

  data() {
    return {
      fetching: false,
      saving: false,
      showSidebar: false,
      edit: false,
      showHistory: false,
      tenant: '',
      // user: getInitialSecurityApprovalFlowForm(),
    }
  },

  computed: {
    ...mapState('auth', ['userData']),
    ...mapState('pages/security/approvalFlowTransaction', ['approvalFlow', 'events']),
    ...mapGetters('pages/security/approvalFlowTransaction', [
      'approvalObject',
      'approvalObjectRoute',
    ]),
    actions() {
      const canApprove = this.canApproveFlow()
      return [
        {
          name: 'cancel',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
        {
          name: 'approve',
          icon: 'done',
          color: 'green',
          tooltip: this.$t('Aprovar'),
          hide: !canApprove,
        },
        {
          name: 'disapprove',
          icon: 'cancel',
          color: 'red',
          tooltip: this.$t('Reprovar'),
          hide: !canApprove,
        },
        {
          name: 'ask-changes',
          icon: 'edit',
          color: 'orange',
          tooltip: this.$t('Solicitar alterações'),
          hide: !canApprove || !this.approvalFlow.canRequestChanges,
        },
      ].filter(a => !a.hide)
    },
    currentApproval() {
      const approvalsArray = []
      this.approvalFlow.steps.forEach(step => {
        if (step.status === 'InProgress') {
          if (step.approvalType === 'Groups') {
            // Para cada step, percorra cada approvalGroup
            step.approvalGroups.forEach(approvalGroup => {
              // Para cada approvalGroup, adicione todos os objetos dentro de "approvals" ao array final
              approvalGroup.approvals.forEach(approval => {
                const approvalCopy = { ...approval }
                approvalCopy.stepId = step.id
                // Adicione o objeto modificado ao array final
                approvalsArray.push(approvalCopy)
              })
            })
          } else if (step.approvalType === 'Owner') {

            const approvalCopy = { ...step.approvalFlowTransactionApproval }
            approvalCopy.stepId = step.id
            // Adicione o objeto modificado ao array final
            approvalsArray.push(approvalCopy)
          }
        }
      })
      return approvalsArray.find(
        approval =>
          approval.responsibleUserId === this.userData.userId && approval.status === 'InProgress'
      )
    },
  },

  async mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/security/approvalFlowTransaction', [
      'cleanApprovalFlowState',
      'fetchApprovalFlow',
      'fetchEvents',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchApprovalFlow(this.$router.currentRoute.params.id)
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async loadEvents() {
      if (
        !this.events?.length ||
        [this.events]?.approvalFlowTransactionId !== this.approvalFlow.id
      ) {
        this.$nextTick(async () => {
          try {
            this.fetching = true
            await this.fetchEvents(this.approvalFlow.id)
          } catch (error) {
            this.showGenericError({ error })
          } finally {
            this.fetching = false
          }
        })
      }
    },

    async saveApprovalFlow() {
      if (await this.$refs.userForm.validate()) {
        try {
          this.saving = true

          const storesUpdated = [...this.user.stores]
          const storesSelected = [...this.user.storesSelected]
          const storesApi = storesUpdated.map(store => {
            const result = { ...store }
            if (!storesSelected.some(storeId => storeId === store.id.toString())) {
              result.isDeleted = true
            }
            return result
          })
          const newStores = storesSelected
            .filter(storeId => !storesApi.some(store => store.id.toString() === storeId))
            .map(storeId => ({ id: storeId }))

          const body = {
            id: this.user.id,
            active: this.user.active,
            name: this.user.name,
            document: this.user.document,
            userName: this.user.userName,
            email: this.user.email,
            allowAllStores: this.user.allowAllStores,
            password: this.user.password,
            stores: [...storesApi, ...newStores],
            accessProfileNames: this.user.accessProfilesSelected,
          }
          await this.$http({
            url: '/api/users',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    canApproveFlow() {
      return (
        this.approvalFlow &&
        (this.approvalFlow.steps.some(step =>
            step.approvalGroups.some(approvalGroup =>
              approvalGroup.approvals.some(
                approve =>
                  approve.responsibleUserId === this.userData.userId &&
                  approve.status === 'InProgress'
              )
            ) && step.status === 'InProgress'
        ) ||
        (this.approvalFlow.steps.some(step =>
            step.approvalFlowTransactionApproval &&
            step.approvalFlowTransactionApproval.responsibleUserId === this.userData.userId &&
            step.approvalFlowTransactionApproval.status === 'InProgress' &&
            step.status === 'InProgress')
        )
      )
    )
    },

    /// action: 'Approve' | 'Disapprove' | 'AskChanges'
    openActionModal(action) {
      this.$refs.modalApproval.show(action, this.currentApproval?.id, this.currentApproval?.stepId)
    },

    cancel() {
      this.$router.push({ name: 'approval-flow-list' })
    },

    getStatusIcon(status) {
      switch (status) {
        case 'InProgress':
          return 'info'
        case 'Disapproved':
        case 'Canceled':
          return 'danger'
        case 'ChangesRequested':
        case 'Holding':
          return 'warning'
        default:
          return 'success'
      }
    },
  },
}
</script>
<style>
.step {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
}

.approval-group {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #e0e0f0;
  margin-bottom: 10px;
}

.status-icon {
  margin-right: 5px;
}

.approved {
  color: green;
}

.rejected {
  color: red;
}

.requested-changes {
  color: orange;
}
</style>
